import React from "react";
import { useInView } from "react-intersection-observer";
import AnimatedText from "../../elements/AnimatedText";
import Box from "../../elements/Box";
import Link from "../../elements/Link";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import ViewportWrapper from "../ViewportWrapper";

interface HeroProps {
  heading: string;
  description?: string;
  paragraphColor?: string;
  contactUs?: boolean;
}

const Hero = ({
  heading,
  description,
  paragraphColor = colors.lightLime,
  contactUs = false
}: HeroProps) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
    <ViewportWrapper ref={ref}>
      <Box
        backgroundColor={colors.black}
        padding={["4rem 1.5rem", "4rem 1.5rem", "5rem 1.5rem"]}
      >
        <AnimatedText
          as="h1"
          color={colors.white}
          fontSize={[6, 6, 7]}
          textAlign="center"
          doesAnimate={inView}
        >
          {heading}
        </AnimatedText>
        <AnimatedText
          as="h2"
          color={paragraphColor}
          textAlign="center"
          maxWidth="720px"
          margin="auto"
          fontSize={["18px", "18px", "22px"]}
          lineHeight={["26px", "26px", "32px"]}
          doesAnimate={inView}
        >
          {description}
        </AnimatedText>
        {contactUs && (
          <Box display="flex" justifyContent="center" fontSize={2}>
            <Text color={colors.mediumLime}>or</Text>
            <Link
              color={colors.mediumLime}
              to="/contact-us"
              margin="auto 0 auto 0.25rem"
              underline
              hoverUnderline
            >
              contact us now
            </Link>
          </Box>
        )}
      </Box>
    </ViewportWrapper>
  );
};

export default Hero;
