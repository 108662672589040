import React from "react";
import Text from "../../elements/Text";
import {
  documentToReactComponents,
  Options
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import Box from "../../elements/Box";

interface TermsProps {
  section: {
    contentfulid: string;
    description: {
      raw: string;
    };
    title: string;
  };
  index: number;
}

const Terms = ({ section, index }: TermsProps) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        return children.map(headingTwo => (
          <Text
            as="h2"
            margin="0"
            key={headingTwo}
            fontWeight="normal"
            fontSize="32px"
            lineHeight="42px"
          >
            {headingTwo}
          </Text>
        ));
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          children?.[0]?.length > 0 && (
            <Box display="flex" margin="1rem 0">
              <Text margin="0">{children?.[0].split(".")[0]}.</Text>
              <Text margin="0 0 0 1rem">
                {children?.[0].split(".").slice(1).join(".").trim()}
              </Text>
            </Box>
          )
        );
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol style={{ padding: 0, margin: "0 0 0 1.25rem" }}>
            {children?.map(listItem => {
              return (
                <li key={listItem.key}>
                  <Text marginLeft="0.5rem">
                    {listItem.props.children?.[0].props?.children?.[0]}
                  </Text>
                </li>
              );
            })}
          </ol>
        );
      }
    }
  };

  const richContent = documentToReactComponents(
    JSON.parse(section.description?.raw),
    options
  );
  return (
    <Box marginTop={[index === 0 ? "0" : "1.5rem"]} id={section.contentfulid}>
      {richContent}
    </Box>
  );
};

export default Terms;
