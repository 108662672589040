import React, { useState } from "react";
import Box from "../../elements/Box";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import Arrow from "../Arrow";
import AnchorLink from "../../elements/AnchorLink";
import HorizontalBorder from "../../elements/HorizontalBorder";

interface ExpansionModalProps {
  items: string[];
  anchorLink: string[];
}

const ExpansionModal = ({ items, anchorLink }: ExpansionModalProps) => {
  const [showLinks, setShowLinks] = useState(false);
  return (
    <Box marginBottom="2rem">
      <Box
        display={["flex", "flex", "none"]}
        alignItems="center"
        onClick={() => setShowLinks(!showLinks)}
        pointer
        marginBottom="10px"
      >
        <Text
          fontWeight={["normal", "normal", "bold"]}
          fontSize={["1", "1", "3"]}
          margin="0 14px 0 0"
          display={"block"}
        >
          Menu
        </Text>
        <Arrow
          width="12px"
          display={["block", "block", "none"]}
          clicked={showLinks}
        />
      </Box>
      <Box
        display={[
          showLinks ? "flex" : "none",
          showLinks ? "flex" : "none",
          "flex"
        ]}
        flexDirection="column"
        margin={["1.5rem 0", "1.5rem 0", "0"]}
      >
        {items.map((category, index) => {
          const isFirst = index === 0;
          return (
            <AnchorLink
              hoverUnderline
              to={`/terms#${anchorLink[index]}`}
              margin={[
                isFirst ? "0" : "1rem 0 0",
                isFirst ? "0" : "1rem 0 0",
                isFirst ? "0" : "0.5rem 0 0"
              ]}
              key={category}
              color={colors.grey}
              hoverNormal
            >
              {category}
            </AnchorLink>
          );
        })}
      </Box>
      <HorizontalBorder
        backgroundColor={"black"}
        display={["block", "block", "none"]}
      />
    </Box>
  );
};

export default ExpansionModal;
