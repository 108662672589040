import styled from "@emotion/styled";
import animation from "../../styles/animation";
import Text from "../Text";
import { TextProps } from "../Text/Text";

interface AnimatedTextProps extends TextProps {
  doesAnimate: boolean;
}

const AnimatedText = styled(Text)<AnimatedTextProps>`
  transform: ${({ doesAnimate }) =>
    doesAnimate ? "translate3d(0,0,0)" : "translate3d(0, 20px, 0)"};
  opacity: ${({ doesAnimate }) => (doesAnimate ? "1" : "0")};
  transition: ${({ doesAnimate }) =>
    doesAnimate ? `all 1s ${animation.transition}` : ""};
`;

export default AnimatedText;
