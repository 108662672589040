import { graphql, PageProps, useStaticQuery } from "gatsby";
import React from "react";
import App from "../components/App";
import ExpansionModal from "../components/ExpansionModal";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import Terms from "../components/Terms";
import Box from "../elements/Box";
import { GetTermsConditionsSectionsQuery } from "../generated/graphql";

const GET_TERMS_CONDITIONS = graphql`
  query GetTermsConditionsSections {
    allContentfulPage(filter: { page: { eq: "Terms & Conditions" } }) {
      edges {
        node {
          section {
            ... on ContentfulTerms {
              description {
                raw
              }
              title
              contentfulid
            }
          }
          title
          description
          page
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const TermsAndConditions = ({ location }: PageProps) => {
  const termsConditionsSections =
    useStaticQuery<GetTermsConditionsSectionsQuery>(GET_TERMS_CONDITIONS);
  const {
    section: data,
    image,
    title,
    description
  } = termsConditionsSections?.allContentfulPage?.edges?.[0]?.node;
  const termsCategories = data?.map(item => item?.title);
  const termAnchorLink = data?.map(item => item?.contentfulid);
  return (
    <App>
      <SEO
        title={title}
        description={description}
        url={location.href}
        image={image?.file?.url}
      />
      <Hero heading="Terms &amp; Conditions"></Hero>
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        maxWidth="1440px"
        width={["90%", "90%", "80%"]}
        margin={["2rem auto", "2rem auto", "4.5rem auto"]}
        position="relative"
      >
        <Box width={["auto", "auto", "20%"]}>
          <Box marginRight={[0, 0, "2rem"]}>
            <ExpansionModal
              items={termsCategories}
              anchorLink={termAnchorLink}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={["auto", "auto", "80%"]}
          margin={["1rem auto", "auto", "auto auto auto 2rem"]}
        >
          {data?.map((section, index) => (
            <Terms section={section} key={section.title} index={index} />
          ))}
        </Box>
      </Box>
    </App>
  );
};

export default TermsAndConditions;
